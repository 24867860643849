import React from "react"
import { CTAButton, DemoButton, LearnMoreButton, Faq, GetStartedButtonWhite } from "../../commonComponents"
import "./styles.css"
import { Layout } from "../../components/Layout"
import { WINDOW_MIN_WIDTH } from "../../utils"
import { useWindowSize } from "../../hooks"
import { Helmet } from "react-helmet"

export default function Security() {
  const isMobile = useWindowSize().width < WINDOW_MIN_WIDTH - 300
  console.log(isMobile)
  return (
    <Layout
      keywords="security, proactive customer support, secure GPT"
      title="Prioritizing Data Security: SOC2 Compliant & Privacy-Focused Approach"
      description="Discover IrisAgent's commitment to data security and privacy. Learn about our SOC2 compliance and how we prioritize safeguarding customer data. Click here to know more."
    >
      <Helmet>
        <link rel="canonical" href="https://irisagent.com/security/" />
      </Helmet>
      <section
        style={{
          paddingTop: "40px",
        }}
      >
        <section
          id=" webinar-bg"
          className=" security-bg"
          style={{
            backgroundImage: isMobile
              ? 'url("/img/security-bg-mobile.svg")'
              : 'url("/img/security-bg.svg")',
            minHeight: isMobile ? "380px" : "579px",
            backgroundSize: "cover",
          }}
        >
          <div className=" security-container">
            <h1 className="security-title">
              <span className="text-highlight">Secure</span>{" "}
              <span className="title-text-color-class">by Default</span>
            </h1>
            <h5 className="header-para">
              We understand that protection of customer data is one of the top
              priorities for every company we work with. At IrisAgent, we take
              privacy and security considerations into every organizational and
              product decision we make.
            </h5>

            <DemoButton></DemoButton>
          </div>
        </section>
      </section>

      <section className="d-flex align-items-center">
        <div className="">
          <div className="row">
            <div className="col-lg-6 order-1 order-lg-1 security-1-img">
              <img
                src="/img/security-1.svg"
                className="img-fluid security-1-img-obj"
                alt="secure"
              />
            </div>
            <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <h4 className="sub-section-1-para">
                IrisAgent ensures that customer data is secure
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section className="d-flex align-items-center">
        <div className="">
          <div className="row">
            <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <div>
                <h4 className="sub-section-2-para">
                  IrisAgent is SOC2 Compliant
                </h4>
                <div className="sub-section-1-button">
                  <LearnMoreButton
                    label="FIND OUT MORE"
                    to="/blog/security-and-compliance-considerations-for-your-support-ops-strategy-and"
                  ></LearnMoreButton>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 security-1-img">
              <img
                src="/img/security-6.svg"
                className="img-fluid security-1-img-obj"
                alt="secure"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="d-flex align-items-center  sub-section-2-container">
        <div style={{ width: "100%" }}>
          <div className="row">
            <SecurityCard
              title="Secure Hosting"
              body="IrisAgent SaaS platform is hosted in a secure cloud infrastructure"
              image="/img/security-2.svg"
            />
            <SecurityCard
              title="Encrypted Data"
              body=" All the data collection happens securely over SSL using REST APIs"
              image="/img/security-3.svg"
            />
            <SecurityCard
              title="Single Sign-On"
              body="We do not require additional login credentials. We authenticate through Google, Microsoft and Salesforce."
              image="/img/security-4.svg"
            />
          </div>
        </div>
      </section>

      <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="What about personally identifiable information or sensitive data like usernames and passwords?"
                  answer="We redact personally identifiable information (PII) such as people's names, email addresses, URLs, SSNs before they are stored into our backend systems. IrisAgent does not require login details or passwords to customer’s CRM solutions (like Salesforce, Zendesk) or incident sources (like Pagerduty). We use OAuth integration to generate access tokens for the integrations. These tokens are securely stored in our database after encrypting with a cryptographic key."
                />
                <Faq
                  id="faq2"
                  question="Does IrisAgent make sure all employees are equipped to handle the sensitive data they handle?"
                  answer="All IrisAgent employees complete our security and privacy training. In addition, engineers must complete a specialized security training. All of our projects go through rigorous security design reviews to make sure we are doing our best to protect customer data."
                />
                <Faq
                  id="faq3"
                  question="How is the data used once it is stored in the IrisAgent database?"
                  answer="Only you and your team (by invite) can access your data. IrisAgent does not modify any of your existing data. However it can optionally add new tags to your data sources. We perform aggregated analytics and generate insights for our users by leveraging case and incident data."
                />
                <Faq
                  id="faq4"
                  question="What happens to my data if I choose to terminate the agreement with IrisAgent?"
                  answer="You can cancel your account any time and we delete all your data after you close your account."
                />
                <Faq
                  id="faq5"
                  question="Do you use Generative AI in your product?"
                  answer="We only use Generative AI for IrisGPT, our internal and external facing chatbot. This is an optional feature that can be turned off if you are not comfortable with using Generative AI. See more details in the next question."
                />
                <Faq
                  id="faq6"
                  question="What models do you use for Generative AI? Where is the data sent?"
                  answer="As mentioned above, Generative AI can be turned off for your account if you choose to. We have two options for GenAI models - private, in-house models or OpenAI-based models. For the former, we train and fine-tune private LLM models on our premises, and no data is shared with any external vendor. For the latter, only the data that you approve will be sent to OpenAI for processing. If you do not want to share any data with OpenAI, we can disable OpenAI and only use private LLM models. If you want to learn more, here are some details on OpenAI data policies if you choose to leverage OpenAI: https://help.openai.com/en/articles/7039943-data-usage-for-consumer-services-faq."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="d-flex align-items-center"
        style={{
          backgroundColor: "rgba(165, 166, 246, 0.14);",
          justifyContent: "center",
        }}
      >
        <div className="">
          <div className="row">
            <div
              className="col-sm order-1 order-lg-1"
              style={{ margin: "48px" }}
            >
              <div>
                <p className="sub-section-4-h1">
                  {" "}
                  Security Questions or Issues?
                </p>
                <p className="sub-section-4-h2">
                  {" "}
                  Our team is here to address any question that you may have.
                </p>
                <div style={{ paddingTop: "3%", paddingBottom: "3%" ,textAlign:"center", display:"flex", gap:"20px", justifyContent:"center"}}>

                    <GetStartedButtonWhite label="Read our Privacy Policy" to="/privacy-policy/"
                   />
                   <LearnMoreButton
                    label="Contact our Security Team"
                    to="/contact/"
                   />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
const SecurityCard = ({ title, body, image }: any) => {
  return (
    <div
      className="col-sm order-1 order-lg-1 "
      style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}
    >
      <img src={image} className=" sub-section-2-img" alt="secure" />
      <h4 className="sub-section-2-h">{title}</h4>
      <p className="sub-section-2-p">{body}</p>
    </div>
  )
}
